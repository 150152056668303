import React from 'react';

import {Wrapper, Title, Text} from './NotFoundPageContent.styles';
import Button from '../Button';
import {MAIN_URL} from '../../constants';

const NotFoundPageContent = () => (
<Wrapper>
  <Title>404</Title>
  <Text>Ooops... ta strona nie istnieje</Text>
  <Button to={MAIN_URL} text='wróć do strony głównej'/>
</Wrapper>
);

export default NotFoundPageContent;
