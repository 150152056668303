import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getColor, getSpacing, getTypographySize, getTypographyWeight} from '../../theme/helpers';
import { Link } from "gatsby";

const getButtonVariantColor = variant => {
  switch (variant) {
    case 'blue':
      return getColor('brandBlue');
    case 'green':
      return getColor('brandGreen');
    default:
      getColor('brandBlue');
  }
}

export const StyledLink = styled(Link)`
  font-weight: ${getTypographyWeight('light')};
  border-radius: 10px;
  font-size: ${getTypographySize('basic')};
  color: ${getColor('basicWhite')};
  text-transform: uppercase;
  background-color: ${({variant}) => getButtonVariantColor(variant)};
  padding: ${getSpacing('basic')};
  text-decoration: none;
  transition: box-shadow 0.25s;
  text-align: center;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }

  @media (min-width: ${MEDIA.MIN}) {
    padding: ${getSpacing('sm')} ${getSpacing('lg')};
    font-size: ${getTypographySize('md')};
  }
`
